<template>
  <div class="culture">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/cultureimg/01.jpg" class="pc_top_img" />
      <img src="~img/cultureimg/03.png" class="iocn_img" />
      <div class="top_box"></div>
      <div class="one_content" :class="{ content_animation: setFadein }">
        <div class="one" ref="oneBox" :style="style">
          <h4>企业愿景</h4>
          <h3>中国智能计算生态领航者</h3>
          <img src="~img/cultureimg/02.png" class="left_img" />
          <img :src="imgList[0]" class="content_img" />
        </div>
      </div>
      <div class="one_content" :class="{ content_animation: setFadein }">
        <div class="tow" ref="twoBox" :style="style">
          <div class="text_box">
            <h4 :class="{ According_h4: !animation }">企业使命</h4>
            <h3 :class="{ According_h3: !animation }">创造核芯价值</h3>
          </div>
          <img :src="imgList[1]" class="content_img" :class="{ According_img: !animation }" />
          <img src="~img/cultureimg/05.png" class="right_img" :class="{ According_img: !animation }" />
        </div>
      </div>
      <div class="one_content box_three" :class="{ content_animation: setFadein }">
        <div class="three" ref="threeBox" :style="style">
          <h4 :class="{ According_h4: !animation2 }">企业价值观</h4>
          <h3 :class="{ According_h3: !animation2 }">创新 奋斗 共赢</h3>
          <img :src="imgList[2]" class="content_img" :class="{ According_img: !animation2 }" />
          <img src="~img/cultureimg/02.png" class="three_img" :class="{ According_img: !animation2 }" />
        </div>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web clearfix" v-if="equipment == 'web'">
      <webNav />
      <img src="~img/cultureimg/09.png" class="web_top_img" />
      <div class="top_box"></div>
      <img src="~img/cultureimg/08.png" class="logo" />
      <div class="web_content clearfix">
        <div class="web_box right clearfix">
          <h3>企业文化</h3>
          <h4>中国智能计算生态领航者</h4>
          <img :src="imgList[0]" class="bg_img" />
          <img src="~img/cultureimg/02.png" class="fen_img" />
        </div>
        <div class="web_box left clearfix">
          <h3>企业使命</h3>
          <h4>创造核芯价值</h4>
          <div class="tow_img clearfix">
            <img :src="imgList[1]" class="bg_img" />
          </div>
          <img src="~img/cultureimg/05.png" class="fen_img" />
        </div>
        <div class="web_box right clearfix">
          <h3>企业价值观</h3>
          <h4>创新 奋斗 共赢</h4>
          <div class="three_img clearfix">
            <img :src="imgList[2]" class="bg_img" />
          </div>
          <img src="~img/cultureimg/02.png" class="fen_img" />
        </div>
      </div>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  name: "culture",
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: undefined, // 当前宽度
      h: undefined, // 视口的高度
      only: true,
      equipment: "", // 当前设备  pc & web
      scrollTop: undefined, // 滚动的距离
      tid: undefined, // 延迟器 用于页面一进来的渐显
      setFadein: false, // 渐显
      style: " transform: translate(0, 0);",
      stylenum: 0,
      animation: false, // 动画阻断器 防止动画一直触发
      animation2: false, // 动画阻断器 防止动画一直触发
      FirstTop: false, // 第一个盒子 的上升动画
      SecondTop: false, // 第二个盒子 的上升动画
      showFooter: true,
      clientType: 1,
      imgList: []
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
      this.clientType = 2;
    } else {
      this.equipment = "pc";
      this.clientType = 1;
    }
    console.log(this.equipment);
    this.getCulture();
    document.title = '企业文化 - 安谋科技'

  },
  beforeRouteEnter(to, from, next) {
    next();
    if (from.name == "brief") {
      if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
      }
    }
  },
  mounted() {
    document.title = '企业文化 - 安谋科技'
    window.addEventListener("scroll", this.handleScroll, true);
    this.w = document.body.clientWidth; // 页面一进来获取 视口宽度
    this.h = document.documentElement.clientHeight; // 视口的高度
    console.log(this.h);
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
    this.Fadein();

    // this.oneStyle = `top:${this.oneBox}px`;
  },
  watch: {
    scrollTop: function (value, oldvalue) {
      if (value > oldvalue) {
        // 下
        this.stylenum--;
        this.style = `transform: translate(0, ${this.stylenum * 2}px);`;
      } else {
        // 上
        this.stylenum++;
        if (value == 0 && this.stylenum != 0) {
          this.stylenum = 0;
          this.style = `transform: translate(0, ${this.stylenum * 2}px);`;
        } else {
          this.style = `transform: translate(0, ${this.stylenum * 2}px);`;
        }
      }
    }
  },
  methods: {
    // 滚动的时候 元素距离顶部的距离
    handleScroll(e) {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.equipment == "pc") {
        let documentop = this.$refs.twoBox.getBoundingClientRect().top;
        let documentop2 = this.$refs.threeBox.getBoundingClientRect().top;
        if (this.h - 100 > documentop && this.animation) {
          this.animation = false;
        } else if (this.h < this.$refs.twoBox.getBoundingClientRect().top) {
          this.animation = true;
        }
        if (this.h - 100 > documentop2 && this.animation2) {
          this.animation2 = false;
        } else if (this.h < this.$refs.twoBox.getBoundingClientRect().top) {
          this.animation2 = true;
        }
      }
    },
    // 渐显
    Fadein() {
      this.$nextTick(() => {
        clearTimeout(this.tid);
        this.tid = setTimeout(() => {
          this.setFadein = true;
        }, 1000);
      });
    },
    async getCulture() {
      const res = await this.axios.get(`/v1/api/culture_pic?clientType=1`);
      console.log(res);
      if (res.code == "00") {
        // this.imgList = res.data;
        res.data.forEach(item => {
          this.imgList.push(item.img);
        });
        console.log(this.imgList);
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
};
</script>


<style lang="less" scoped>
.culture {
  position: relative;
  border-top: 1px solid;
  background: #000;
  .pc {
    .pc_top_img {
      position: absolute;
      top: 0;
      left: 0;
    }
    .iocn_img {
      width: 0.38rem /* 38/100 */;
      position: absolute;
      top: 2.21rem /* 221/100 */;
      right: 0.9rem /* 119/100 */;
    }
    .top_box {
      width: 100%;
      height: 3rem;
    }
    .one_content {
      width: 16.42rem /* 1642/100 */;
      height: 8rem /* 500/100 */;
      // background-color: skyblue;
      opacity: 0;
      margin: auto;
      position: relative;
      transition: 0.5s all;
      .one {
        position: absolute;
        transition: 1s all;
        top: 0;
        overflow: hidden;
        h4 {
          font-size: 0.35rem /* 35/100 */;
          font-weight: 400;
          color: #00b6da;
        }
        h3 {
          font-size: 0.72rem /* 72/100 */;
          font-weight: 400;
          color: #ffffff;
        }
        .left_img {
          width: 1.16rem /* 116/100 */;
          height: 1.11rem /* 111/100 */;
          position: absolute;
          left: 8.5rem /* 850/100 */;
          top: 0.8rem /* 44/100 */;
          z-index: 999;
        }
        .three_img {
          width: 1.16rem /* 116/100 */;
          height: 1.11rem /* 111/100 */;
          position: absolute;
          top: 0.8rem /* 44/100 */;
          left: 5.5rem /* 850/100 */;
        }
        .content_img {
          margin-top: 0.1rem /* 10/100 */;
          transition: 1s;
        }
      }
      .tow {
        position: absolute;
        transition: 1s all;
        top: 0;
        overflow: hidden;
        .text_box {
          float: right;
          h4 {
            font-size: 0.35rem /* 35/100 */;
            font-weight: 400;
            color: #00b6da;
            text-align: right;
            opacity: 0;
          }
          h3 {
            font-size: 0.72rem /* 72/100 */;
            font-weight: 400;
            color: #ffffff;
            opacity: 0;
          }
        }
        .right_img {
          width: 1.16rem /* 116/100 */;
          height: 1.11rem /* 111/100 */;
          position: absolute;
          right: 5rem /* 500/100 */;
          top: 0.8rem /* 44/100 */;
          opacity: 0;
        }
        .content_img {
          margin-top: 1.8rem /* 10/100 */;
          opacity: 0;
          transition: 1s;
        }
      }
      .three {
        position: absolute;
        transition: 1s all;
        top: 0;
        overflow: hidden;
        h4 {
          font-size: 0.35rem /* 35/100 */;
          font-weight: 400;
          color: #00b6da;
          opacity: 0;
        }
        h3 {
          font-size: 0.72rem /* 72/100 */;
          font-weight: 400;
          color: #ffffff;
          opacity: 0;
        }
        .left_img {
          width: 1.16rem /* 116/100 */;
          height: 1.11rem /* 111/100 */;
          position: absolute;
          left: 8.5rem /* 850/100 */;
          top: 0.8rem /* 44/100 */;
          opacity: 0;
        }
        .three_img {
          width: 1.16rem /* 116/100 */;
          height: 1.11rem /* 111/100 */;
          position: absolute;
          top: 0.8rem /* 44/100 */;
          left: 5.5rem /* 850/100 */;
          opacity: 0;
        }
        .content_img {
          margin-top: 0.1rem /* 10/100 */;
          opacity: 0;
          transition: 1s;
        }
      }
      .content_img:hover {
        transform: scale(1.1);
      }
      .According_h4 {
        animation: Fadein 0.5s linear alternate forwards;
      }
      .According_h3 {
        animation: Fadein 0.5s 0.5s linear alternate forwards;
      }
      .According_img {
        animation: Fadein 0.5s 1s linear alternate forwards;
      }
    }
    .box_three {
      height: 7rem;
    }

    .content_animation {
      animation: Fadein 1s linear alternate forwards;
    }
    @keyframes Fadein {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .web {
    position: relative;
    .web_top_img {
      width: 100%;
      position: absolute;
      top: 0;
    }
    .top_box {
      height: 140px;
      // background-color: hotpink;
    }
    .logo {
      width: 126px;
      position: absolute;
      top: 90px;
      right: 25px;
    }
    .web_box {
      width: 93.33%;
      // background-color: skyblue;
      overflow: hidden;
      position: relative;
      margin-bottom: 54px;
      h3 {
        font-size: 18px;
        font-weight: 400;
        color: #0db5da;
      }
      h4 {
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .bg_img {
        height: 242px;
        width: auto;
        float: right;
      }
      .tow_img {
        position: relative;
        height: 242px;
        img {
          position: absolute;
          left: 20%;
          transform: translate(-50%);
        }
      }
      .three_img {
        position: relative;
        height: 242px;
        img {
          position: absolute;
          left: 70%;
          transform: translate(-50%);
        }
      }
      .fen_img {
        width: 59px;
        position: absolute;
        right: 0;
        top: 35px;
      }
    }
    .right {
      float: right;
    }
    .left {
      text-align: right;
      .fen_img {
        left: 25px;
      }
    }
    .foot {
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>


