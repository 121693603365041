<template>
  <div class="up">
    <img
        src="~img/indexImg/020.png"
        class="up-img"
        @click="webUp"
        v-show="isShow"
      />
  </div>
</template>



<script>
export default {
  data() {
    return {
      isShow: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); //创建滚动监听，页面滚动回调handleScroll方法
  },
  methods: {
    webUp() {
      document.documentElement.scrollTop = 0;
    },
    handleScroll: function() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 400) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
};
</script>
<style lang="less" scoped>
.up {
  background: #000;
  position: fixed;
  right: 0.95rem /* 38/40 */;
  bottom: 0.85rem /* 34/40 */;
  z-index: 999;
  .up-img {
    width: 1.775rem /* 71/40 */;
    height: 1.775rem /* 71/40 */;
  }
}
</style>
